
import Airtable from './packages/airtable/lib/manifest.json'
import Bigquery from './packages/bigquery/lib/manifest.json'
import Couchdb from './packages/couchdb/lib/manifest.json'
import Dynamodb from './packages/dynamodb/lib/manifest.json'
import Elasticsearch from './packages/elasticsearch/lib/manifest.json'
import Firestore from './packages/firestore/lib/manifest.json'
import Gcs from './packages/gcs/lib/manifest.json'
import Googlesheets from './packages/googlesheets/lib/manifest.json'
import Graphql from './packages/graphql/lib/manifest.json'
import Influxdb from './packages/influxdb/lib/manifest.json'
import Mailgun from './packages/mailgun/lib/manifest.json'
import Minio from './packages/minio/lib/manifest.json'
import Mongodb from './packages/mongodb/lib/manifest.json'
import Mssql from './packages/mssql/lib/manifest.json'
import Mysql from './packages/mysql/lib/manifest.json'
import N8n from './packages/n8n/lib/manifest.json'
import Oracledb from './packages/oracledb/lib/manifest.json'
import Postgresql from './packages/postgresql/lib/manifest.json'
import Redis from './packages/redis/lib/manifest.json'
import Restapi from './packages/restapi/lib/manifest.json'
import S3 from './packages/s3/lib/manifest.json'
import Sendgrid from './packages/sendgrid/lib/manifest.json'
import Slack from './packages/slack/lib/manifest.json'
import Smtp from './packages/smtp/lib/manifest.json'
import Snowflake from './packages/snowflake/lib/manifest.json'
import Stripe from './packages/stripe/lib/manifest.json'
import Twilio from './packages/twilio/lib/manifest.json'
import Typesense from './packages/typesense/lib/manifest.json' 

import AirtableOperation from './packages/airtable/lib/operations.json'
import BigqueryOperation from './packages/bigquery/lib/operations.json'
import CouchdbOperation from './packages/couchdb/lib/operations.json'
import DynamodbOperation from './packages/dynamodb/lib/operations.json'
import ElasticsearchOperation from './packages/elasticsearch/lib/operations.json'
import FirestoreOperation from './packages/firestore/lib/operations.json'
import GcsOperation from './packages/gcs/lib/operations.json'
import GooglesheetsOperation from './packages/googlesheets/lib/operations.json'
import GraphqlOperation from './packages/graphql/lib/operations.json'
import InfluxdbOperation from './packages/influxdb/lib/operations.json'
import MailgunOperation from './packages/mailgun/lib/operations.json'
import MinioOperation from './packages/minio/lib/operations.json'
import MongodbOperation from './packages/mongodb/lib/operations.json'
import MssqlOperation from './packages/mssql/lib/operations.json'
import MysqlOperation from './packages/mysql/lib/operations.json'
import N8nOperation from './packages/n8n/lib/operations.json'
import OracledbOperation from './packages/oracledb/lib/operations.json'
import PostgresqlOperation from './packages/postgresql/lib/operations.json'
import RedisOperation from './packages/redis/lib/operations.json'
import RestapiOperation from './packages/restapi/lib/operations.json'
import S3Operation from './packages/s3/lib/operations.json'
import SendgridOperation from './packages/sendgrid/lib/operations.json'
import SlackOperation from './packages/slack/lib/operations.json'
import SmtpOperation from './packages/smtp/lib/operations.json'
import SnowflakeOperation from './packages/snowflake/lib/operations.json'
import StripeOperation from './packages/stripe/lib/operations.json'
import TwilioOperation from './packages/twilio/lib/operations.json'
import TypesenseOperation from './packages/typesense/lib/operations.json' 

import airtableSvg from './packages/airtable/lib/icon.svg'
import bigquerySvg from './packages/bigquery/lib/icon.svg'
import couchdbSvg from './packages/couchdb/lib/icon.svg'
import dynamodbSvg from './packages/dynamodb/lib/icon.svg'
import elasticsearchSvg from './packages/elasticsearch/lib/icon.svg'
import firestoreSvg from './packages/firestore/lib/icon.svg'
import gcsSvg from './packages/gcs/lib/icon.svg'
import googlesheetsSvg from './packages/googlesheets/lib/icon.svg'
import graphqlSvg from './packages/graphql/lib/icon.svg'
import influxdbSvg from './packages/influxdb/lib/icon.svg'
import mailgunSvg from './packages/mailgun/lib/icon.svg'
import minioSvg from './packages/minio/lib/icon.svg'
import mongodbSvg from './packages/mongodb/lib/icon.svg'
import mssqlSvg from './packages/mssql/lib/icon.svg'
import mysqlSvg from './packages/mysql/lib/icon.svg'
import n8nSvg from './packages/n8n/lib/icon.svg'
import oracledbSvg from './packages/oracledb/lib/icon.svg'
import postgresqlSvg from './packages/postgresql/lib/icon.svg'
import redisSvg from './packages/redis/lib/icon.svg'
import restapiSvg from './packages/restapi/lib/icon.svg'
import s3Svg from './packages/s3/lib/icon.svg'
import sendgridSvg from './packages/sendgrid/lib/icon.svg'
import slackSvg from './packages/slack/lib/icon.svg'
import smtpSvg from './packages/smtp/lib/icon.svg'
import snowflakeSvg from './packages/snowflake/lib/icon.svg'
import stripeSvg from './packages/stripe/lib/icon.svg'
import twilioSvg from './packages/twilio/lib/icon.svg'
import typesenseSvg from './packages/typesense/lib/icon.svg' 

export const allManifests = {
 Airtable,
Bigquery,
Couchdb,
Dynamodb,
Elasticsearch,
Firestore,
Gcs,
Googlesheets,
Graphql,
Influxdb,
Mailgun,
Minio,
Mongodb,
Mssql,
Mysql,
N8n,
Oracledb,
Postgresql,
Redis,
Restapi,
S3,
Sendgrid,
Slack,
Smtp,
Snowflake,
Stripe,
Twilio,
Typesense 
 }

export const allOperations = {
 Airtable: AirtableOperation,
Bigquery: BigqueryOperation,
Couchdb: CouchdbOperation,
Dynamodb: DynamodbOperation,
Elasticsearch: ElasticsearchOperation,
Firestore: FirestoreOperation,
Gcs: GcsOperation,
Googlesheets: GooglesheetsOperation,
Graphql: GraphqlOperation,
Influxdb: InfluxdbOperation,
Mailgun: MailgunOperation,
Minio: MinioOperation,
Mongodb: MongodbOperation,
Mssql: MssqlOperation,
Mysql: MysqlOperation,
N8n: N8nOperation,
Oracledb: OracledbOperation,
Postgresql: PostgresqlOperation,
Redis: RedisOperation,
Restapi: RestapiOperation,
S3: S3Operation,
Sendgrid: SendgridOperation,
Slack: SlackOperation,
Smtp: SmtpOperation,
Snowflake: SnowflakeOperation,
Stripe: StripeOperation,
Twilio: TwilioOperation,
Typesense: TypesenseOperation 
 }

export const allSvgs = {
 airtable: airtableSvg,
bigquery: bigquerySvg,
couchdb: couchdbSvg,
dynamodb: dynamodbSvg,
elasticsearch: elasticsearchSvg,
firestore: firestoreSvg,
gcs: gcsSvg,
googlesheets: googlesheetsSvg,
graphql: graphqlSvg,
influxdb: influxdbSvg,
mailgun: mailgunSvg,
minio: minioSvg,
mongodb: mongodbSvg,
mssql: mssqlSvg,
mysql: mysqlSvg,
n8n: n8nSvg,
oracledb: oracledbSvg,
postgresql: postgresqlSvg,
redis: redisSvg,
restapi: restapiSvg,
s3: s3Svg,
sendgrid: sendgridSvg,
slack: slackSvg,
smtp: smtpSvg,
snowflake: snowflakeSvg,
stripe: stripeSvg,
twilio: twilioSvg,
typesense: typesenseSvg 
 }

